import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  useGetUserPhotoForUserMutation,
  useGetUserProfileForUserQuery,
} from "../../redux/slices/graphApiSlice";
import {
  Box,
  Avatar as MuiAvatar,
  Typography,
  Grid,
  Fade,
  Tooltip,
  Chip as MuiChip,
} from "@mui/material";
import { deepOrange, deepPurple, green, pink } from "@mui/material/colors";
import { spacing } from "@mui/system";

const Avatar = styled(MuiAvatar)``;

const Chip = styled(MuiChip)(spacing);

const PinkAvatar = styled(Avatar)`
  background-color: ${pink[500]};
`;

const GreenAvatar = styled(Avatar)`
  background-color: ${green[500]};
`;

const OrangeAvatar = styled(Avatar)`
  background-color: ${deepOrange[500]};
`;

const PurpleAvatar = styled(Avatar)`
  background-color: ${deepPurple[500]};
`;

interface IAzureADAvatarProps {
  userId?: string;
  size?: number;
  big?: boolean;
  small?: boolean;
  tagged?: boolean;
  refetch?: boolean;
  chip?: boolean;
  nameOnly?: boolean;
  pictureOnly?: boolean;
}
function AzureADAvatar({ ...props }: IAzureADAvatarProps) {
  const {
    data: userProfile,
    isLoading,
    isSuccess,
    refetch: getUserProfile,
  } = useGetUserProfileForUserQuery(props.userId || "", {
    skip: !props.userId,
  });

  const [
    getUserPhoto,
    { data: userPhoto, isLoading: photoLoading, isSuccess: photoSuccess },
  ] = useGetUserPhotoForUserMutation();

  const [initials, setInitials] = useState("");

  useEffect(() => {
    if (userProfile?.displayName) {
      // get the first letter of each word in the displayName

      const initials = userProfile?.displayName
        .replace(/[^a-zA-Z0-9 ]/g, "") // remove all non-alphanumeric characters
        ?.split(" ")
        .map((word: string) => word.charAt(0))
        .join("");

      // take only the first 2 letters
      const initialsShort = initials?.substring(0, 3).toUpperCase();

      setInitials(initialsShort);
    }
  }, [userProfile]);

  useEffect(() => {
    if (props.userId) {
      getUserProfile();
      getUserPhoto(props.userId);
    }
  }, [props.userId]);

  //   ownerProfile={{ ...ownerProfile, avatar: ownerPhotoSuccess ? ownerPhoto : null }}
  //   contract={contract?.data}
  //   updateContract={updateContract}

  // select a color avatar if no photo based on the name so it won't change on a page refresh
  const colors = [pink[500], green[500], deepOrange[500], deepPurple[500]];

  // get the index of every later in the initials in the alphabet
  const letterIndex = initials
    ?.split("")
    .map((letter) => letter.charCodeAt(0) - 65);

  // get the sum of all the indexes
  const letterIndexSum = letterIndex?.reduce((a, b) => a + b, 0);

  // get the remainder of the sum divided by the number of colors
  const remainder = letterIndexSum % colors.length;

  // get the color at the remainder index
  const randomColor = colors[remainder];

  if (!props.userId) {
    return <span />;
  }

  const size = props.size || 32;

  return (
    <Tooltip
      title={`${userProfile?.displayName} (${userProfile?.mail})`}
      arrow
      placement="top"
    >
      <React.Fragment>
        {props.nameOnly && userProfile?.displayName}

        {props.big && (
          <Grid container direction="column" alignItems="center" gap={2}>
            <Grid item display="flex">
              <Avatar
                sx={{
                  backgroundColor: !photoLoading ? randomColor : null,
                  width: 128,
                  height: 128,
                }}
                alt={userProfile?.displayName}
                title={userProfile?.displayName}
                src={photoSuccess && userPhoto ? userPhoto : undefined}
              />
            </Grid>

            <Grid item>
              <Typography variant="body2" component="div" gutterBottom>
                <Box fontWeight="fontWeightMedium">
                  {userProfile?.displayName}
                </Box>
                <Box fontWeight="fontWeightRegular">
                  {userProfile?.jobTitle}
                </Box>
              </Typography>
            </Grid>
          </Grid>
        )}

        {props.small && (
          <Grid
            container
            direction="row"
            alignItems="center"
            gap={2}
            flexWrap="nowrap"
          >
            <Grid item display="flex">
              <Avatar
                alt={userProfile?.displayName}
                title={userProfile?.displayName}
                src={photoSuccess && userPhoto ? userPhoto : undefined}
                sx={{
                  backgroundColor: !photoLoading ? randomColor : null,
                  width: size,
                  height: size,
                  fontSize: size / 2,
                }}
              >
                {!photoLoading && !photoSuccess ? initials : null}
              </Avatar>
            </Grid>

            <Grid item sx={{ overflow: "hidden" }}>
              <Typography
                variant="body2"
                sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
              >
                {userProfile?.displayName}
              </Typography>
            </Grid>
          </Grid>
        )}

        {props.tagged && (
          <Chip
            key={props.userId}
            size="small"
            mr={1}
            mb={1}
            label={`@${userProfile?.displayName}`}
            color="default"
          />
        )}

        {props.chip && (
          <Chip
            key={props.userId}
            size="small"
            mr={1}
            mb={1}
            label={userProfile?.displayName}
            color="default"
          />
        )}

        {props.pictureOnly && (
          <Tooltip
            title={`${userProfile?.displayName} (${userProfile?.mail})`}
            arrow
            placement="top"
          >
            <Avatar
              alt={userProfile?.displayName}
              src={photoSuccess && userPhoto ? userPhoto : undefined}
              sx={{
                backgroundColor: !photoLoading ? randomColor : null,
                width: size,
                height: size,
                fontSize: size / 2,
              }}
            >
              {!photoLoading && !photoSuccess ? initials : null}
            </Avatar>
          </Tooltip>
        )}
      </React.Fragment>
    </Tooltip>
  );
}

export default AzureADAvatar;
